.ContactItem {
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
  .contact {
    display: flex;
    align-items: center;
    background-color: #191d2b;
    padding: 3rem 0;
    .right-items {
      margin-left: 2rem;
    }
    img {
      padding: 1rem;
      border: 1px solid #037fff;
      margin-left: 2rem;
      width: 10px;
    }
    .right-items {
      h6 {
        font-size: 1.4rem;
        font-weight: 500;
      }
    }
  }
}

.ContactPage {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 6rem;
  @media screen and (max-width: 1270px) {
    grid-template-columns: repeat(1, 1fr);
    .map-sect {
      width: 100%;
      height: 50vh;
      margin-bottom: 1rem;
    }
  }
  .contact-sect {
    width: 100%;
    text-align: left;
  }
}
.map-sect {
  width: 97%;
  padding: 1rem;
  background-color: #191d2b;
  iframe {
    width: 100%;
    height: 100%;
  }
}

.contact-sect {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.title {
  margin-bottom: 5rem;
}
