.SkillsSection{
   
}

.skills-container{
    .skill-title{
        font-size: 1.4rem;
        font-weight: 400;
    }

    .skill-bar{
        display: flex;
        align-items: center;
        .skill-text{
        }
        .skill-progress{
            width: 100%;
           .progress{
               width: 100%;
               height: .5rem;
               background-color: red;
               margin-left: 1rem;
               background-color: #0381ff3f;
               position: relative;
               .inner-pregress{
                    position: absolute;
                    background-color: #037FFF;
                    height: 100%;
                    bottom: 0;
                    left: 0;
               }
           }
        }
    }
}