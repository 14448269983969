.PortfolioPage {
}

.portfolis {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 2rem;
  column-gap: 1rem;
  @media screen and (max-width: 1600px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 900px) {
    grid-template-columns: repeat(1, 1fr);
  }
  .portfolio {
    .image-data {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 42px;
        left: 15px;
        height: calc(100% - 90px);
        width: calc(100% - 30px);

        transform-origin: center;
        transform: scale(0);
        transition: all 0.4s ease-in-out;
      }
      &:hover::before {
        transform: scale(1);
      }
      img {
        width: 100%;
        height: 28vh;
        object-fit: contain;
      }

      .hover-items {
        list-style: none;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.6);
        top: 70%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 3.5rem 7rem;
        visibility: hidden;
        li {
          a {
            padding: 1rem;
            text-decoration: none;
            font-family: inherit;
            font-size: 1rem;
            border-radius: 10px;
            background-color: #037fff;
            color: white;
            &:not(:last-child) {
              margin-right: 1rem;
            }
          }
        }
      }

      &:hover {
        .hover-items {
          transition: all 0.5s ease-in-out 0.2s;
          visibility: visible;
          top: 50%;
        }
      }
    }

    h5 {
      font-size: 1.7rem;
      font-weight: 400;
      margin-bottom: 1rem;
      color: white;
      transition: all 0.3s ease-in-out;
      &:hover {
        color: #037fff;
        cursor: pointer;
      }
    }
    .accordion {
      margin-bottom: 0.5rem;
    }
  }
}
